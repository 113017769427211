import * as React from 'react';
import List from '@mui/material/List';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleIcon from '@mui/icons-material/People';
import TocIcon from '@mui/icons-material/Toc';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {
  CollapsibleNavigationButton,
  // ExternalLink,
  NavigationButton,
} from './NavigationButton';
import { UserType } from './UserType';
import { Auth } from 'aws-amplify';
import { generatePath, useLocation } from 'react-router-dom';
import './SidePanel.scss';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../utils/consts';
import ListItemText from '@mui/material/ListItemText';
import { ListItemButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { UserData } from '../../data/user';
import AssessmentIcon from '@mui/icons-material/Assessment';

import {
  countUnreadConversations,
  getFirebaseConfig,
  getOrCreateChatWithAdmin,
} from '../../utils/chat';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, signInAnonymously } from 'firebase/auth';
import ReviewsIcon from '@mui/icons-material/Reviews';

interface NavigationProps {
  userType: UserType;
  onboarding: boolean;
  isSuperAdmin: boolean;
  user: UserData;
  toggleDrawer: () => void;
}

export const Navigation = ({
  userType,
  onboarding,
  isSuperAdmin,
  user,
  toggleDrawer,
}: NavigationProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'navigation' });
  const [unreadChats, setUnreadChats] = useState(0);
  const isAdmin = userType === UserType.Admin;

  const handleSignOut = (event: any) => {
    event.preventDefault();
    localStorage.removeItem('cypressId');
    Auth.signOut();
  };

  const location = useLocation();

  function doesPathMatch(path: string) {
    return location.pathname == path;
  }

  useEffect(() => {
    const firebaseConfig = getFirebaseConfig();
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const auth = getAuth(app);
    signInAnonymously(auth);
    if (user && !isAdmin) {
      async function initFirabaseChat() {
        await getOrCreateChatWithAdmin(
          user?.user_uid,
          db,
          userType === UserType.Student ? 'student' : 'company',
          `${user?.first_name} ${user?.last_name}`,
          user?.avatar,
          user?.email,
        );
      }
      initFirabaseChat();
    }
    const countUnreadConversationsHandler = async () => {
      let userUid: string;
      if (userType === UserType.Admin) {
        userUid = 'admin';
      } else {
        userUid = user?.user_uid;
      }
      countUnreadConversations(userUid, db).then((res) => {
        setUnreadChats(res);
      });
    };
    countUnreadConversationsHandler();
  }, []);

  const jobButton = () => {
    if (userType === UserType.Company) {
      return (
        <NavigationButton
          title={t('post_freelance_job')}
          icon={<ViewListOutlinedIcon />}
          relativeUrl={Routes.JobPostRoute}
          // TODO:
          // onClick={() => console.log('clicked')}
          isSelected={doesPathMatch(Routes.JobPostRoute)}
          isCollapsed={false}
          onClose={toggleDrawer}
        />
      );
    } else if (userType === UserType.Student) {
      return (
        <NavigationButton
          title={t('browse_jobs')}
          icon={<ViewListOutlinedIcon />}
          relativeUrl={Routes.BrowseJobsRoute}
          isSelected={doesPathMatch(Routes.BrowseJobsRoute)}
          isCollapsed={false}
          onClose={toggleDrawer}
        />
      );
    } else {
      return null;
    }
  };

  const chatSupportRoute = generatePath(Routes.NewChatRoute, {
    proposalId: 'admin',
  });

  return (
    <>
      <List
        sx={{
          width: '100%',
          maxWidth: 300,
          bgcolor: 'background.paper',
        }}
        component="nav"
        aria-labelledby="navigation"
      >
        <NavigationButton
          title={t('dashboard')}
          icon={<DashboardIcon />}
          relativeUrl={
            userType === UserType.Admin
              ? Routes.AdminDashboardRoute
              : `${Routes.DashboardRoute}?${
                  userType === 1 ? 'student' : 'company'
                }`
          }
          isSelected={
            userType === UserType.Admin
              ? doesPathMatch(Routes.AdminDashboardRoute)
              : doesPathMatch(Routes.DashboardRoute)
          }
          isCollapsed={false}
          onClose={toggleDrawer}
        />

        {userType === UserType.Student && (
          <>
            <NavigationButton
              title={t('my_profile')}
              icon={<PersonIcon />}
              relativeUrl={Routes.MyProfileRoute}
              isSelected={doesPathMatch(Routes.MyProfileRoute)}
              isCollapsed={false}
              onClose={toggleDrawer}
            />
            <NavigationButton
              title={'Assessments'}
              icon={<AssessmentIcon />}
              relativeUrl={Routes.StudentAssessments}
              isSelected={doesPathMatch(Routes.StudentAssessments)}
              isCollapsed={false}
              onClose={toggleDrawer}
            />
          </>
        )}

        {userType !== UserType.Admin ? (
          <>
            {jobButton()}
            {userType === UserType.Company &&
              Date.parse(user?.created_at) >
                Date.parse('2024-02-05 00:00:00.000000+00') && (
                <NavigationButton
                  title={t('post_fulltime_job')}
                  icon={<TocIcon />}
                  relativeUrl={Routes.JobDirectPostRoute}
                  isSelected={doesPathMatch(Routes.JobDirectPostRoute)}
                  isCollapsed={false}
                  onClose={toggleDrawer}
                />
              )}
            <CollapsibleNavigationButton
              title={t('settings')}
              icon={<SettingsIcon />}
            >
              <NavigationButton
                title={t('profile')}
                padding={5}
                relativeUrl={Routes.ProfileSettingsRoute}
                isSelected={doesPathMatch(Routes.ProfileSettingsRoute)}
                isCollapsed={true}
                onClose={toggleDrawer}
                id="profile-nav"
              />

              <NavigationButton
                title={t('account')}
                padding={5}
                relativeUrl={Routes.FinancialSettingsRoute}
                isSelected={doesPathMatch(Routes.FinancialSettingsRoute)}
                isCollapsed={true}
                onClose={toggleDrawer}
              />

              {/*<NavigationButton*/}
              {/*  title={t('notifications')}*/}
              {/*  padding={5}*/}
              {/*  relativeUrl={Routes.NotificationSettingsRoute}*/}
              {/*  isSelected={doesPathMatch(Routes.NotificationSettingsRoute)}*/}
              {/*  isCollapsed={true}*/}
              {/*  onClose={toggleDrawer}*/}
              {/*/>*/}
            </CollapsibleNavigationButton>
            <CollapsibleNavigationButton
              title={t('support')}
              icon={<InfoOutlinedIcon />}
            >
              {onboarding && (
                <NavigationButton
                  title={t('onboarding')}
                  padding={5}
                  relativeUrl={Routes.OnboardingSupportRoute}
                  isSelected={doesPathMatch(Routes.OnboardingSupportRoute)}
                  isCollapsed={true}
                  onClose={toggleDrawer}
                />
              )}

              <ListItemButton
                sx={{
                  pl: 5,
                  marginBottom: '3px',
                  ml: 2,
                  mr: 2,
                }}
              >
                <a
                  href={`https://www.minite.works/${
                    userType === UserType.Company
                      ? 'kb-companies'
                      : 'kb-highflyers'
                  }`}
                  target="_blank"
                >
                  <ListItemText
                    primary={t('knowledge_base')}
                    primaryTypographyProps={{
                      style: { color: '#393A42' },
                    }}
                  />
                </a>
              </ListItemButton>

              <NavigationButton
                title={t('support_case')}
                padding={5}
                relativeUrl={Routes.LogCaseSupportRoute}
                isSelected={doesPathMatch(Routes.LogCaseSupportRoute)}
                isCollapsed={true}
                onClose={toggleDrawer}
              />
              <NavigationButton
                title="Chat"
                padding={5}
                relativeUrl={chatSupportRoute}
                isSelected={doesPathMatch(chatSupportRoute)}
                isCollapsed={true}
                onClose={toggleDrawer}
              />
            </CollapsibleNavigationButton>
            <NavigationButton
              title={t('invoices')}
              icon={<ReceiptIcon />}
              relativeUrl={Routes.Invoices}
              isSelected={doesPathMatch(Routes.Invoices)}
              isCollapsed={false}
              onClose={toggleDrawer}
            />
            <NavigationButton
              title={'Reviews'}
              icon={<ReviewsIcon />}
              relativeUrl={Routes.ReviewsRoute}
              isSelected={doesPathMatch(Routes.ReviewsRoute)}
              isCollapsed={false}
              onClose={toggleDrawer}
            />
            <NavigationButton
              title={'Refer a Friend'}
              relativeUrl={Routes.ReferFriend}
              isSelected={doesPathMatch(Routes.ReferFriend)}
              isCollapsed={false}
              onClose={toggleDrawer}
              icon={<PeopleIcon />}
            />
          </>
        ) : (
          <>
            <NavigationButton
              title={'Jobs'}
              padding={5}
              relativeUrl={Routes.AdminJobsRoute}
              isSelected={doesPathMatch(Routes.AdminJobsRoute)}
              isCollapsed={true}
              onClose={toggleDrawer}
            />
            <NavigationButton
              title={'Companies'}
              padding={5}
              relativeUrl={Routes.CompaniesRoute}
              isSelected={doesPathMatch(Routes.CompaniesRoute)}
              isCollapsed={true}
              onClose={toggleDrawer}
            />
            <NavigationButton
              title={'Students'}
              padding={5}
              relativeUrl={Routes.AdminStudentsRoute}
              isSelected={doesPathMatch(Routes.AdminStudentsRoute)}
              isCollapsed={true}
              onClose={toggleDrawer}
            />
            <NavigationButton
              title={'Reviews'}
              padding={5}
              relativeUrl={Routes.AdminReviewsRoute}
              isSelected={doesPathMatch(Routes.AdminReviewsRoute)}
              isCollapsed={true}
              onClose={toggleDrawer}
            />
            <NavigationButton
              title={'Hours'}
              padding={5}
              relativeUrl={Routes.HoursRoute}
              isSelected={doesPathMatch(Routes.HoursRoute)}
              isCollapsed={true}
              onClose={toggleDrawer}
            />
            <NavigationButton
              title={'Tasks'}
              padding={5}
              relativeUrl={Routes.TasksRoute}
              isSelected={doesPathMatch(Routes.TasksRoute)}
              isCollapsed={true}
              onClose={toggleDrawer}
            />
            <NavigationButton
              title={'Config'}
              padding={5}
              relativeUrl={Routes.ConfigRoute}
              isSelected={doesPathMatch(Routes.ConfigRoute)}
              isCollapsed={true}
              onClose={toggleDrawer}
            />
            <NavigationButton
              title={'Statistics'}
              padding={5}
              relativeUrl={Routes.StatisticsRoute}
              isSelected={doesPathMatch(Routes.StatisticsRoute)}
              isCollapsed={true}
              onClose={toggleDrawer}
            />
            <NavigationButton
              title={`Chats (${unreadChats})`}
              padding={5}
              relativeUrl={Routes.AdminNewChatRoute}
              isSelected={doesPathMatch(Routes.AdminNewChatRoute)}
              isCollapsed={true}
              onClose={toggleDrawer}
            />
            {isSuperAdmin && (
              <>
                <NavigationButton
                  title={'Revenue'}
                  padding={5}
                  relativeUrl={Routes.AdminRevenueRoute}
                  isSelected={doesPathMatch(Routes.AdminRevenueRoute)}
                  isCollapsed={true}
                  onClose={toggleDrawer}
                />
                <NavigationButton
                  title={'Invoices'}
                  padding={5}
                  relativeUrl={Routes.AdminInvoicesRoute}
                  isSelected={doesPathMatch(Routes.AdminInvoicesRoute)}
                  isCollapsed={true}
                  onClose={toggleDrawer}
                />
                <NavigationButton
                  title={'Event logs'}
                  padding={5}
                  relativeUrl={Routes.AdminEventLogs}
                  isSelected={doesPathMatch(Routes.AdminEventLogs)}
                  isCollapsed={true}
                  onClose={toggleDrawer}
                />
                <NavigationButton
                  title={'Edits'}
                  padding={5}
                  relativeUrl={Routes.AdminEditsRoute}
                  isSelected={doesPathMatch(Routes.AdminEditsRoute)}
                  isCollapsed={true}
                  onClose={toggleDrawer}
                />
              </>
            )}
          </>
        )}

        <div onClick={handleSignOut}>
          <NavigationButton
            title={t('log_out')}
            icon={<LogoutIcon />}
            color="#EA4335"
            isCollapsed={false}
            onClose={toggleDrawer}
          />
        </div>
      </List>
    </>
  );
};
