import React, { useEffect, useRef, useState } from 'react';
import './CreateJob.scss';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { JobForm } from '../../stores/job-form';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import {
  JobLocation,
  JobLocations,
  JobStatuses,
  JobType,
  JobTypes,
  StudentTypes,
  useUpdateJobRequest,
} from '../../data/jobs';
import { useTranslation } from 'react-i18next';
import MiniteSnackbar from '../../components/snackbar';
import { LoadingButton } from '../../components/LoadingButton';
import { usePostJobWizardLoading } from './store';
import GenericModal from '../../components/generic-modal/GenericModal';

function a11yProps(index: number) {
  return {
    className: 'student-level-form-tabs',
    id: `student-level-tab-${index}`,
    'aria-controls': `student-level-tabpanel-${index}`,
  };
}

interface Props {
  handleBack: () => void;
  handleNext: () => void;
  job: any;
  serviceFee: number;
}

const getValidationSchema = (jobType: JobType, studentLevel = 1) => {
  const generalRules = {
    job_location: yup.string().required(),
    office_location: yup.string().when('job_location', (jobLocation) => {
      if (jobLocation !== 'remote') {
        return yup.string().required();
      }
      return yup.string().nullable().optional();
    }),
    students_needed: yup.number().required().min(1),
  };

  if (jobType === JobTypes.ONGOING) {
    if (studentLevel === 1) {
      return yup
        .object()
        .shape({
          budget: yup.number().min(20).max(300).required(),
          hours_estimated: yup.number().min(8).required(),
          ...generalRules,
        })
        .required();
    } else if (studentLevel === 0) {
      return yup
        .object()
        .shape({
          budget: yup.number().min(17).max(19).required(),
          hours_estimated: yup.number().min(8).required(),
          ...generalRules,
        })
        .required();
    }
  } else if (jobType === JobTypes.ONE_TIME) {
    return yup
      .object()
      .shape({
        budget: yup.number().min(100).max(999_999).required(),
        hours_estimated: yup
          .number()
          .min(0)
          .nullable()
          .transform((val) => (!val && val !== 0 ? null : val)),
        ...generalRules,
      })
      .required();
  } else if (jobType === JobTypes.PREMIUM) {
    return yup
      .object()
      .shape({
        budget: yup.number().min(500).max(999_999).required(),
        hours_estimated: yup
          .number()
          .min(0)
          .nullable()
          .transform((val) => (!val && val !== 0 ? null : val)),
        ...generalRules,
      })
      .required();
  }
};

const studentTypes = {
  0: StudentTypes.EntryLevel,
  1: StudentTypes.Regular,
};

interface CategoryProps {
  title: string;
  isSelected: boolean;
  onClick: React.MouseEventHandler;
  code?: string;
}

const CategoryButton = ({
  title,
  isSelected,
  onClick,
  code,
}: CategoryProps) => {
  const disabledStyle = {
    opacity: '0.5',
    border: '2px solid #C7D2E2',
  };

  return (
    <div
      className="createjob-category-button"
      onClick={onClick}
      style={isSelected ? {} : disabledStyle}
      data-testid={`category-${code || title}`}
    >
      <img
        src="/images/bulb.png"
        alt="Light bulb"
        className="createjob-category-button-image"
      />
      <h4 className="createjob-category-button-title">{title}</h4>
    </div>
  );
};

export const IdealStudentStep = ({ handleBack, handleNext, job }: Props) => {
  const studentLevelDefault =
    job.student_type === StudentTypes.EntryLevel ? 0 : 1;
  const [studentLevel, setStudentLevel] = useState(studentLevelDefault);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);

  let schema = getValidationSchema(job.job_type, studentLevel);
  const { mutateAsync: updateJobRequest } = useUpdateJobRequest();
  const loading = usePostJobWizardLoading((state) => state.loading);
  const setLoading = usePostJobWizardLoading((state) => state.setLoading);
  const refetchJob = usePostJobWizardLoading((state) => state.refetchJob);

  const handleStudentTypeChange = (
    event: React.SyntheticEvent,
    newValue: number,
  ) => {
    setStudentLevel(newValue);
    if (newValue === 0) {
      setShowModal(true);
    }
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'create_job.step_budget_location',
  });

  // Info message alert, it is commented out as it is not being used at the moment - if ever reset make sure to include necessary React elements and variables
  /*
  const InfoMessage = (props: { type: JobType }): ReactElement<any, any> => {
    if (props.type === JobTypes.ONE_TIME) {
      return (
        <Alert severity="info" className="posting-fee-info">
          <AlertTitle className="info-title">
            {serviceFee}% Posting {t('budget.posting_fee_title')}
          </AlertTitle>
        </Alert>
      );
    } else if (props.type === JobTypes.PREMIUM) {
      return (
        <Alert severity="info" className="posting-fee-info">
          <AlertTitle className="info-title">
            25% Matching {t('budget.posting_fee_title')}
          </AlertTitle>
          <span className="info-text">
            {t('budget.message_title')}. {t('budget.posting_fee_description')}
          </span>
        </Alert>
      );
    } else if (props.type === JobTypes.ONGOING) {
      return (
        <Alert severity="info" className="posting-fee-info">
          <AlertTitle className="info-title">
            {serviceFee}% Service {t('budget.posting_fee_title')}
          </AlertTitle>
        </Alert>
      );
    }

    return <></>;
  };
*/
  useEffect(() => {
    if (job.job_type === JobTypes.ONGOING) {
      schema = getValidationSchema(JobTypes.ONGOING, studentLevel);
    }
  }, [studentLevel]);

  const {
    handleSubmit,
    formState: { isValid, errors },
    control,
    register,
    setValue,
    watch,
  } = useForm<{
    budget: number;
    hours_estimated: number;
    job_location: JobLocation;
    office_location: string;
    students_needed: number;
  }>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      budget: Number(job.budget) || null,
      hours_estimated: Number(job.hours_estimated) || null,
      students_needed: job.students_needed,
      job_location: job.job_location,
      office_location: job.office_location,
    },
    reValidateMode: 'onChange',
  });
  const budgetValue = watch('budget'); // Watch the budget field
  const [computedValue, setComputedValue] = useState(0);

  useEffect(() => {
    if (budgetValue !== undefined) {
      setComputedValue(budgetValue * 1.3);
    }
  }, [budgetValue]);
  const isMobileView = useMediaQuery('(max-width: 700px)');

  const [location, setLocation] = useState(job.job_location || 'remote');
  const officeLocationRef = useRef<HTMLInputElement>(null);

  const handleLocationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue,
  ) => {
    setLocation(newValue);
    setValue('job_location', newValue);
  };
  useEffect(() => {
    if (location === JobLocations.OFFICE || location === JobLocations.HYBRID) {
      officeLocationRef.current?.focus();
    }
  }, [location]);

  const onSubmit = async (data: Partial<JobForm>) => {
    const {
      budget,
      hours_estimated,
      job_location,
      students_needed,
      office_location,
    } = data;

    try {
      setLoading(true);
      const payload = {
        job: {
          budget: +budget,
          hours_estimated: +hours_estimated,
          student_type: studentTypes[studentLevel],
          job_location,
          students_needed: +students_needed,
          office_location,
        },
      };
      await updateJobRequest({ job_uid: job.job_uid, body: payload });
      await refetchJob(job.job_uid);
      handleNext();
    } catch (error) {
      setErrorMessage('Failed to update job!');
    } finally {
      setLoading(false);
    }
  };

  const [job_location, setJobLocation] = useState(job.job_location);

  const handleLocationClick = (location: JobLocation) => {
    setJobLocation(location);
  };

  const isDisabled =
    job?.job_type === JobTypes.ONE_TIME &&
    job?.job_status === JobStatuses.PENDING_APPROVAL;

  return (
    <>
      <section className="ideal-student-section">
        <form className="ideal-student-form">
          <div className="form-inputs">
            <div className="createjob-form-section-container">
              <br />
              <FormControl sx={{ width: '45%', marginRight: '5%' }}>
                <TextField
                  type="number"
                  id="students-count"
                  data-testid="student-needs"
                  label={t('students_needed')}
                  error={Boolean(errors.students_needed)}
                  variant="outlined"
                  inputProps={{
                    min: 1,
                  }}
                  sx={{ width: '100%' }}
                  {...register('students_needed')}
                  disabled={isDisabled}
                />
              </FormControl>
              {(job.job_type === JobTypes.ONE_TIME ||
                job.job_type === JobTypes.PREMIUM) && (
                <>
                  <FormControl sx={{ width: '50%' }}>
                    <TextField
                      {...register('hours_estimated')}
                      helperText={`* ${t('optional')}`}
                      type="number"
                      id="hours"
                      data-testid="expected-hours"
                      error={Boolean(errors.hours_estimated)}
                      label={t('budget.one_time.expected_hours')}
                      variant="outlined"
                    />
                    <br />
                  </FormControl>

                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      {...register('budget')}
                      helperText={`${t('budget.minimum')} €${
                        job?.job_type === JobTypes.PREMIUM ? '500' : '100'
                      }`}
                      error={Boolean(errors.budget)}
                      type="number"
                      id="budget"
                      data-testid="budget"
                      label={t('budget.one_time.total_budget')}
                      variant="outlined"
                      disabled={isDisabled}
                    />
                  </FormControl>
                </>
              )}
              {job.job_type === JobTypes.ONGOING && (
                <>
                  <FormControl sx={{ width: '50%' }}>
                    <TextField
                      {...register('hours_estimated')}
                      helperText={t('minimum')}
                      error={Boolean(errors.hours_estimated)}
                      type="number"
                      id="hours"
                      data-testid="expected-hours-per-week"
                      label={t('budget.ongoing.expected_hours')}
                      variant="outlined"
                    />
                  </FormControl>

                  <Box sx={{ width: '100%', marginBottom: '8px' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs
                        value={studentLevel}
                        onChange={handleStudentTypeChange}
                        TabIndicatorProps={{
                          style: {
                            backgroundColor: '#DEB900',
                          },
                        }}
                      >
                        <Tab
                          label="Entry-Level High-Flyer"
                          {...a11yProps(0)}
                          data-testd="student-level-entry"
                        />
                        <Tab
                          label="High-Flyer"
                          {...a11yProps(1)}
                          data-testd="student-level-regular"
                        />
                      </Tabs>
                    </Box>
                  </Box>

                  <FormControl
                    sx={{ marginTop: '5%', marginRight: '5%', width: '47%' }}
                  >
                    <TextField
                      {...register('budget')}
                      error={Boolean(errors.budget)}
                      helperText={
                        studentLevel === 1
                          ? t('budget.ongoing.minimum_1')
                          : t('budget.ongoing.minimum_2')
                      }
                      type="number"
                      id="budget"
                      label={t('budget.ongoing.hourly_rate')}
                      variant="outlined"
                      data-testid="student-rate"
                    />
                  </FormControl>
                  <FormControl
                    sx={{
                      marginTop: '4.5%',
                      width: '48%',
                      fontSize: '11.7px',
                      color: 'grey',
                    }}
                  >
                    {t('budget.ongoing.hourly_rate_excl_VAT')}
                    <p
                      style={{
                        marginTop: '0px',
                        fontSize: '20px',
                        color: 'black',
                      }}
                    >
                      {computedValue.toFixed(2)}
                    </p>
                  </FormControl>
                </>
              )}
            </div>
          </div>
          <div className="createjob-form-section-container">
            <h2>{t('location.title')}</h2>
            {!isMobileView && (
              <FormControl>
                <Controller
                  name="job_location"
                  control={control}
                  render={({ field }) => {
                    return (
                      <RadioGroup
                        {...field}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '10px',
                          marginTop: '10px',
                        }}
                        value={location}
                        onChange={handleLocationChange}
                      >
                        <FormControlLabel
                          value={JobLocations.REMOTE}
                          control={<Radio />}
                          data-testid="location-remote"
                          label={t('location.remote')}
                        />
                        <FormControlLabel
                          value={JobLocations.OFFICE}
                          control={<Radio />}
                          data-testid="location-office"
                          label={t('location.office')}
                        />
                        <FormControlLabel
                          value={JobLocations.HYBRID}
                          control={<Radio />}
                          data-testid="location-hybrid"
                          label={t('location.hybrid')}
                        />
                      </RadioGroup>
                    );
                  }}
                />
                {location !== 'remote' && (
                  <div className="createjob-form-section-container">
                    <h2
                      className="createjob-subtitle smallerSubtitle"
                      style={{ marginLeft: '-14%' }}
                    >
                      {t('office_location')}
                    </h2>
                    <FormControl sx={{ marginLeft: '-14%', width: '100%' }}>
                      <TextField
                        type="text"
                        id="office-location"
                        data-testid="office-location"
                        error={Boolean(errors.office_location)}
                        variant="outlined"
                        sx={{ width: '100%' }}
                        {...register('office_location')}
                        disabled={isDisabled}
                        inputRef={officeLocationRef}
                      />
                    </FormControl>
                  </div>
                )}
              </FormControl>
            )}

            {isMobileView && (
              <div className="location-container">
                <div className="createjob-grid-container">
                  <Grid container spacing={1}>
                    <Grid item>
                      <CategoryButton
                        title={t('location.remote')}
                        onClick={() => handleLocationClick(JobLocations.REMOTE)}
                        isSelected={job_location === JobLocations.REMOTE}
                      />
                    </Grid>
                    <Grid item>
                      <CategoryButton
                        title={t('location.office')}
                        onClick={() => handleLocationClick(JobLocations.OFFICE)}
                        isSelected={job_location === JobLocations.OFFICE}
                      />
                    </Grid>
                    <Grid item>
                      <CategoryButton
                        title={t('location.hybrid')}
                        onClick={() => handleLocationClick(JobLocations.HYBRID)}
                        isSelected={job_location === JobLocations.HYBRID}
                      />
                    </Grid>
                  </Grid>
                </div>
                {job_location !== JobLocations.REMOTE && (
                  <div className="createjob-form-section-container">
                    <h2
                      className="createjob-subtitle smallerSubtitle"
                      style={{ marginLeft: '0px' }}
                    >
                      {t('office_location')}
                    </h2>
                    <FormControl sx={{ marginLeft: '0px', width: '100%' }}>
                      <TextField
                        type="text"
                        id="office-location"
                        data-testid="office-location"
                        error={Boolean(errors.office_location)}
                        variant="outlined"
                        sx={{ width: '100%' }}
                        {...register('office_location')}
                        disabled={isDisabled}
                        inputRef={officeLocationRef}
                      />
                    </FormControl>
                  </div>
                )}
              </div>
            )}
          </div>
        </form>
      </section>

      <div className="createjob-content-buttons">
        <Button
          variant="text"
          onClick={handleBack}
          data-testid="previous-step-post-job-student-info"
        >
          {t('back')}
        </Button>
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          isDisabled={!isValid || loading}
          buttonText={t('continue')}
          isLoading={loading}
          confirmButtonTestId="save-ideal-student-post-job"
        />
      </div>

      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => setErrorMessage(null)}
      />
      <GenericModal
        open={showModal}
        title="Entry-Level High-Flyer"
        content={t('entry_level_modal')}
        confirmButtonText="OK"
        handleConfirm={() => setShowModal(false)}
      />
    </>
  );
};
