import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { TInputDate } from '../stores/student-signup-form';

dayjs.extend(utc);

export function formatDate(date: string | TInputDate, template: string) {
  if (!date) return '';
  return dayjs(date).utc().format(template);
}
