import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getNames } from 'country-list';

import './IdentityModal.scss';
import React, { useState } from 'react';
import { LoadingButton } from '../LoadingButton';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (gender, ethnicity, nationality) => void;
  isLoading?: boolean;
}

const IdentityModal = ({ open, onClose, onSubmit, isLoading }: Props) => {
  const [gender, setGender] = useState('');
  const [ethnicity, setEthnicity] = useState('');
  const [nationality, setNationality] = useState('');
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard.diversity_and_inclusion_banner',
  });

  const handleGenderChange = (event: SelectChangeEvent) => {
    setGender(event.target.value as string);
  };

  const handleEthnicityChange = (event: SelectChangeEvent) => {
    setEthnicity(event.target.value as string);
  };

  const handleNationalityChange = (
    e: React.ChangeEvent,
    value: string | null,
  ) => {
    setNationality(value as string);
  };

  const handleOnSubmit = () => {
    if (gender !== '' && ethnicity !== '' && nationality !== '') {
      onSubmit(gender, ethnicity, nationality);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle className="content-title">
        {t('d&i_title')}
        {!!onClose && (
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="content-text">
          {t('d&i_text')}
        </DialogContentText>
        <br />
        <FormControl variant="standard" fullWidth>
          <InputLabel>{t('gender')}</InputLabel>
          <Select
            labelId="gender-label"
            id="gender-simple-select"
            value={gender}
            label="Gender"
            onChange={handleGenderChange}
            required
          >
            <MenuItem value={'m'}>{t('male')}</MenuItem>
            <MenuItem value={'f'}>{t('female')}</MenuItem>
            <MenuItem value={'nb'}>{t('non-binary')}</MenuItem>
            <MenuItem value={'other'}>{t('other')}</MenuItem>
            <MenuItem value={'n/a'}>{t('prefer_not')}</MenuItem>
          </Select>
        </FormControl>
        <br />
        <br />
        <FormControl variant="standard" fullWidth>
          <InputLabel>{t('ethnicity')}</InputLabel>
          <Select
            labelId="ethnicity-label"
            id="ethnicity-simple-select"
            value={ethnicity}
            label="Ethnicity"
            onChange={handleEthnicityChange}
            required
          >
            <MenuItem value={'african_american'}>
              {t('african_american')}
            </MenuItem>
            <MenuItem value={'asian'}>{t('asian')}</MenuItem>
            <MenuItem value={'caucasian'}>{t('caucasian')}</MenuItem>
            <MenuItem value={'hispanic'}>{t('hispanic')}</MenuItem>
            <MenuItem value={'middle_eastern'}>{t('middle_eastern')}</MenuItem>
            <MenuItem value={'other'}>{t('other')}</MenuItem>
            <MenuItem value={'n/a'}>{t('prefer_not')}</MenuItem>
          </Select>
        </FormControl>
        <br />
        <br />
        <Autocomplete
          id="combo-box-country"
          options={getNames()}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={t('nationality')}
            />
          )}
          value={nationality}
          onChange={handleNationalityChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('do_later')}</Button>
        <LoadingButton
          className="button"
          variant="text"
          onClick={handleOnSubmit}
          buttonText={t('submit')}
          isLoading={isLoading}
          confirmButtonTestId="send-identity"
        />
      </DialogActions>
    </Dialog>
  );
};

export default IdentityModal;
