import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Skeleton,
  TextField,
  useMediaQuery,
  Autocomplete,
  Chip,
  Alert,
} from '@mui/material';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { JobTypes, useUpdateJobRequest } from '../../data/jobs';
import { styled } from '@mui/system';
import {
  JobForm,
  Language,
  Skill,
  useManageJobForm,
} from '../../stores/job-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Category, useFetchLangsAndSkills } from '../../data/shared';
import { LoadingButton } from '../../components/LoadingButton';
import MiniteSnackbar from '../../components/snackbar';
import { usePostJobWizardLoading } from './store';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

interface CategoryProps {
  title: string;
  isSelected: boolean;
  onClick: React.MouseEventHandler;
  code?: string;
}

interface Props {
  categories: Category[];
  handleBack: () => void;
  handleNext: () => void;
  job: any;
  refetchCategories: () => void;
}

const FormSkeleton = () => (
  <Grid className="category-skeleton-wrapper" container spacing={1}>
    <Grid item>
      <Skeleton animation="wave" className="skeleton-item" />
    </Grid>
    <Grid item>
      <Skeleton animation="wave" className="skeleton-item" />
    </Grid>
    <Grid item>
      <Skeleton animation="wave" className="skeleton-item" />
    </Grid>
    <Grid item>
      <Skeleton animation="wave" className="skeleton-item" />
    </Grid>
    <Grid item>
      <Skeleton animation="wave" className="skeleton-item" />
    </Grid>
  </Grid>
);

const CategoryButton = ({
  title,
  isSelected,
  onClick,
  code,
}: CategoryProps) => {
  const disabledStyle = {
    opacity: '0.5',
    border: '2px solid #C7D2E2',
  };

  return (
    <div
      className="createjob-category-button"
      onClick={onClick}
      style={isSelected ? {} : disabledStyle}
      data-testid={`category-${code || title}`}
    >
      <h4 className="createjob-category-button-title">{title}</h4>
    </div>
  );
};

const StyledChip = styled(Chip)({
  tag: {
    backgroundColor: '#DEB900',
  },
});

export const AddDetailsStep = ({
  categories,
  handleBack,
  handleNext,
  job,
  refetchCategories,
}: Props) => {
  const { jobFormState } = useManageJobForm();
  const { mutateAsync: updateJobRequest } = useUpdateJobRequest();
  const loading = usePostJobWizardLoading((state) => state.loading);
  const setLoading = usePostJobWizardLoading((state) => state.setLoading);
  const refetchJob = usePostJobWizardLoading((state) => state.refetchJob);

  const schema = yup
    .object()
    .shape({
      category_uid: yup.string().required(),
      sub_category_uid: yup.string().optional(),
      sub_category_name: yup
        .string()
        .nullable()
        .optional()
        .when(['category_uid', 'sub_category_uid'], (category, subCategory) => {
          if (
            category &&
            categories.find((item) => item.category_uid === category)?.code !==
              'community_building' &&
            categories.find((item) => item.category_uid === category)?.code !==
              'customer_support'
          ) {
            if (!subCategory) {
              return yup.string().required('required');
            }
            return yup.string().optional().nullable();
          } else {
            return yup.string().optional().nullable();
          }
        }),
      job_start_at: yup.date().required(),
      job_end_at: yup.date().nullable().optional(),
      languages: yup.array().min(1).max(5).required(),
      skills: yup.array().min(1).max(5).required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    control,
    setValue,
    trigger,
    getValues,
  } = useForm<JobForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      category_uid: job.category_uid,
      sub_category_uid: job.sub_category_uid || undefined,
      sub_category_name: job.sub_category_name,
      job_start_at: job.job_start_at || jobFormState.job_start_at,
      job_end_at: job.job_end_at || null,
      languages: job.languages,
      skills: job.skills,
    },
    reValidateMode: 'onChange',
  });
  const [subCategories, setSubCategories] = useState([]);
  const isMobileView = useMediaQuery('(max-width: 700px)');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isOtherCategory, setIsOtherCategory] = useState(false);
  const [jobStartsASAP, setJobStartsASAP] = useState(() => {
    const jobStartDate = new Date(job.job_start_at);
    const today = new Date();

    return job.job_start_at === null || jobStartDate < today;
  });

  const [limitReachedSkills, setDisableInputSkills] = useState(
    job.skills.length >= 5,
  );
  const [limitReachedLang, setDisableInputLang] = useState(
    job.languages.length >= 5,
  );

  const [currentLang, setCurrentLang] = useState(job?.languages);
  const [currentSkills, setCurrentSkills] = useState(job?.skills);

  const [
    { data: languages = [], isLoading: isLangLoading },
    { data: skills = [], isLoading: isSkillLoading },
  ] = useFetchLangsAndSkills();

  const categoryUid = useWatch({
    control,
    defaultValue: job.category_uid,
    name: 'category_uid',
  });

  const isCommunityBuildingCode =
    categories?.find((item) => item.category_uid === categoryUid)?.code ===
    'community_building';

  const isCustomerSupportCode =
    categories?.find((item) => item.category_uid === categoryUid)?.code ===
    'customer_support';

  useEffect(() => {
    if (job && job.languages.length === 0 && languages.length > 0) {
      const defaultLanguages = languages
        .filter(
          (language) =>
            language.language_name === 'English' ||
            language.language_name === 'Dutch',
        )
        .map((item) => ({
          language_uid: item.language_uid,
          language_name: item.language_name,
        }));
      if (defaultLanguages.length > 0) {
        setValue('languages', defaultLanguages);
        setCurrentLang(defaultLanguages);
      }
    }
  }, [job, languages, setValue]);

  useEffect(() => {
    if (categoryUid && categories.length > 0) {
      setSubCategories(
        categories.find((category) => category.category_uid === categoryUid)
          ?.sub_categories,
      );
    }
  }, [categoryUid, categories]);

  useEffect(() => {
    if (isCommunityBuildingCode || isCustomerSupportCode) {
      setValue('sub_category_name', '');
      setValue('sub_category_uid', undefined);
    }
  }, [isCommunityBuildingCode, isCustomerSupportCode]);

  const handleJobStartsASAP = () => {
    const asapDate = new Date();
    // Set to previous day
    if (!jobStartsASAP) {
      asapDate.setDate(asapDate.getDate() - 1);
      setValue('job_start_at', asapDate);
      setJobStartsASAP(true);
    } else {
      setValue('job_start_at', new Date());
      setJobStartsASAP(false);
    }
  };

  const onSubmit = async (data: Partial<JobForm>) => {
    const {
      category_uid,
      sub_category_uid,
      sub_category_name,
      job_start_at,
      job_end_at,
      languages,
      skills,
    } = data;

    try {
      setLoading(true);
      const payload = {
        job: {
          category_uid,
          sub_category_uid,
          sub_category_name,
          job_start_at: job_start_at,
          job_end_at: job_end_at,
        },
        languages: languages.map((item) => ({
          language_uid: item.language_uid,
        })),
        skills: skills.map((item) => ({
          skill_uid: item.skill_uid,
        })),
      };
      await updateJobRequest({ job_uid: job.job_uid, body: payload });

      if (isOtherCategory) {
        refetchCategories();
      }
      await refetchJob(job.job_uid);
      handleNext();
    } catch (error) {
      setErrorMessage('Failed to update job!');
    } finally {
      setLoading(false);
    }
  };

  const checkDisableSkills = useCallback(
    (option) => limitReachedSkills && !getValues().skills.includes(option),
    [limitReachedSkills, getValues().skills],
  );

  const checkDisableLang = useCallback(
    (option) => limitReachedLang && !getValues().languages.includes(option),
    [limitReachedLang, getValues().languages],
  );

  const filterHr = (categories: Category[]) => {
    return categories.filter((category) => category.code !== 'hr');
  };

  const defaultButtonStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    width: 'auto',
    height: '36px',
    borderRadius: '4px',
    fontFamily: 'Sofia W05 Semi Bold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: ' 0.4px',
    textTransform: 'uppercase',
    color: '#393A42',
  };

  const { t } = useTranslation();
  return (
    <>
      <section className="createjob-section">
        <form className="createjob-form">
          <div className="createjob-form-section-container">
            <h2 className="createjob-subtitle">
              {isMobileView
                ? t('create_job.step_category_skills.category.title_mobile')
                : t('create_job.step_category_skills.category.title')}
            </h2>

            <div className="createjob-grid-container">
              <Grid container spacing={1}>
                {categories.length > 0 ? (
                  filterHr(categories).map((category) => {
                    return (
                      <Grid item key={category.category_uid}>
                        <Controller
                          key={category.category_uid}
                          name="category_uid"
                          control={control}
                          render={({ field: { value } }) => (
                            <CategoryButton
                              title={t(`categories.${category.code}`)}
                              onClick={() => {
                                setIsOtherCategory(category.code === 'other');
                                setValue('sub_category_name', '');
                                setValue(
                                  'sub_category_uid',
                                  category.code === 'other' ? '' : undefined,
                                );
                                setValue('category_uid', category.category_uid);
                                trigger();
                              }}
                              isSelected={category.category_uid === value}
                              code={category.code}
                            />
                          )}
                        />
                      </Grid>
                    );
                  })
                ) : (
                  <FormSkeleton />
                )}
              </Grid>
            </div>
            {categories.length > 0 &&
              categoryUid &&
              !isCommunityBuildingCode &&
              !isCustomerSupportCode && (
                <FormControl>
                  <Controller
                    name="sub_category_uid"
                    control={control}
                    render={({ field, field: { value } }) => {
                      return (
                        <RadioGroup {...field} sx={{ marginTop: '10px' }}>
                          {subCategories
                            ?.filter(
                              (subCategory) => subCategory.sub_category_uid,
                            )
                            .map((subCategory) => {
                              return (
                                <FormControlLabel
                                  value={subCategory.sub_category_uid}
                                  control={<Radio />}
                                  data-testid={`sub-categories-${subCategory.sub_category_name.replace(
                                    ' ',
                                    '-',
                                  )}`}
                                  label={
                                    subCategory.code
                                      ? t(`sub_categories.${subCategory.code}`)
                                      : subCategory.sub_category_name
                                  }
                                  key={subCategory.sub_category_uid}
                                />
                              );
                            })}
                          <FormControlLabel
                            name="sub_category_name"
                            key={`${categoryUid}.sub_category_name`}
                            value=""
                            control={<Radio />}
                            label={
                              <TextField
                                disabled={
                                  isOtherCategory && !value ? false : !!value
                                }
                                variant="outlined"
                                {...register('sub_category_name')}
                                placeholder={t(
                                  'create_job.step_category_skills.other_helper',
                                )}
                              />
                            }
                          />
                        </RadioGroup>
                      );
                    }}
                  />
                </FormControl>
              )}
          </div>
          <div className="form-inputs">
            <p className="form-title">
              {t('create_job.step_category_skills.language_skills.title')}
            </p>

            {!isLangLoading && (
              <FormControl
                variant="outlined"
                sx={{ width: '100%', marginBottom: '16px' }}
              >
                <Controller
                  control={control}
                  name="languages"
                  defaultValue={[]}
                  render={({ field, field: { onChange } }) => (
                    <Autocomplete
                      multiple
                      id="languages-select"
                      data-testid="languages-select"
                      limitTags={5}
                      options={languages}
                      getOptionDisabled={checkDisableLang}
                      onChange={(_, data) => {
                        onChange(data);
                        setDisableInputLang(data.length >= 5);
                        setCurrentLang(data);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.language_uid === value.language_uid
                      }
                      defaultValue={[
                        ...languages
                          .filter((language) =>
                            job.languages
                              .map((item) => item.language_uid)
                              .includes(language.language_uid),
                          )
                          .map((item) => {
                            return {
                              language_uid: item.language_uid,
                              language_name: item.language_name,
                            };
                          }),
                        ...(job?.languages?.length === 0
                          ? languages
                              .filter(
                                (language) =>
                                  language.language_name === 'English' ||
                                  language.language_name === 'Dutch',
                              )
                              .map((item) => {
                                return {
                                  language_uid: item.language_uid,
                                  language_name: item.language_name,
                                };
                              })
                          : []),
                      ]}
                      getOptionLabel={(option: Language) =>
                        option.language_name
                      }
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <StyledChip
                            variant="outlined"
                            label={`${option.language_name}`}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      onBlur={() => {
                        setValue('languages', currentLang);
                        trigger();
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...field}
                          label={t(
                            'create_job.step_category_skills.language_skills.language',
                          )}
                          variant="outlined"
                          onBlur={() => {
                            setValue('languages', currentLang);
                            trigger();
                          }}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            )}

            {!isSkillLoading && (
              <FormControl variant="outlined" sx={{ width: '100%' }}>
                <Controller
                  control={control}
                  name="skills"
                  defaultValue={[]}
                  render={({ field, field: { onChange } }) => (
                    <Autocomplete
                      multiple
                      id="skills-select"
                      data-testid="skills-select"
                      limitTags={5}
                      options={skills}
                      getOptionDisabled={checkDisableSkills}
                      onChange={(_, data) => {
                        onChange(data);
                        setDisableInputSkills(data.length >= 5);
                        setCurrentSkills(data);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.skill_uid === value.skill_uid
                      }
                      defaultValue={[
                        ...skills
                          .filter((skill) =>
                            job.skills
                              .map((item) => item.skill_uid)
                              .includes(skill.skill_uid),
                          )
                          .map((item) => {
                            return {
                              skill_uid: item.skill_uid,
                              skill_name: item.skill_name,
                            };
                          }),
                      ]}
                      getOptionLabel={(option: Skill) => option.skill_name}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <StyledChip
                            variant="outlined"
                            label={`${option.skill_name}`}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      onBlur={() => {
                        setValue('skills', currentSkills);
                        trigger();
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...field}
                          label={t(
                            'create_job.step_category_skills.language_skills.skills',
                          )}
                          variant="outlined"
                          onBlur={() => {
                            setValue('skills', currentSkills);
                            trigger();
                          }}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            )}
          </div>

          <div className="createjob-form-section-container">
            <h2 className="createjob-subtitle">
              {job.job_type === JobTypes.ONGOING
                ? t('create_job.step_category_skills.when_start')
                : `When is the project due?`}
            </h2>
            {job.job_type === JobTypes.ONGOING && (
              <div
                style={{
                  paddingTop: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: isMobileView ? '12%' : '12%',
                  marginTop: '20px',
                }}
              >
                {jobStartsASAP && t('create_job.step_category_skills.asap')}
                <Button
                  sx={{
                    ...defaultButtonStyle,
                    width: 'auto%',
                  }}
                  color="primary"
                  variant="outlined"
                  onClick={() => handleJobStartsASAP()}
                >
                  {jobStartsASAP
                    ? t('create_job.step_category_skills.or_specific_date')
                    : t('create_job.step_category_skills.asap')}
                </Button>
              </div>
            )}
            <Grid
              className="createjob-form-inputs"
              container
              direction="row"
              justifyContent="space-between"
            >
              {job.job_type === JobTypes.ONGOING && !jobStartsASAP && (
                <FormControl className="job_start_at">
                  <Controller
                    name="job_start_at"
                    control={control}
                    render={({ field: { onChange, ...restField } }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label={t(
                            'create_job.step_category_skills.start_date',
                          )}
                          onChange={(startDate) => {
                            onChange(startDate);
                            trigger();
                          }}
                          minDate={Date.now()}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <TextField
                              sx={{
                                width: isMobileView ? '72%' : '84%',
                                marginTop: isMobileView ? '-17%' : '-11%',
                                marginLeft: isMobileView ? '28%' : '20%',
                              }}
                              type="text"
                              ref={inputRef}
                              helperText={
                                !!errors.job_start_at &&
                                `Must be a valid date MM/DD/YYYY`
                              }
                              inputProps={inputProps}
                              error={Boolean(errors.job_start_at)}
                              variant="outlined"
                              id="startDate"
                              label={t(
                                'create_job.step_category_skills.start_date',
                              )}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={InputProps}
                            />
                          )}
                          {...restField}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </FormControl>
              )}

              {job.job_type === JobTypes.ONE_TIME && (
                <FormControl>
                  <Controller
                    name="job_end_at"
                    control={control}
                    render={({ field: { onChange, ...restField } }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label={t('create_job.step_category_skills.end_date')}
                          onChange={(endDate) => {
                            onChange(endDate);
                            trigger();
                          }}
                          minDate={Date.now()}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <TextField
                              type="text"
                              ref={inputRef}
                              helperText={
                                !!errors.job_end_at
                                  ? errors?.job_end_at?.message ===
                                    'must be greater than start date'
                                    ? `${errors?.job_end_at?.message}`
                                    : 'Must be a valid date MM/DD/YYYY'
                                  : '* Optional'
                              }
                              error={Boolean(errors.job_end_at)}
                              inputProps={inputProps}
                              variant="outlined"
                              id="endDate"
                              label={t(
                                'create_job.step_category_skills.end_date',
                              )}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={InputProps}
                            />
                          )}
                          {...restField}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </FormControl>
              )}
            </Grid>
          </div>
        </form>
      </section>

      <div className="createjob-content-buttons">
        <Button
          variant="text"
          onClick={handleBack}
          data-testid="open-dashboard-post-job-type"
        >
          {t('general.back')}
        </Button>
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          isDisabled={!isValid || loading}
          buttonText={t('general.continue')}
          isLoading={loading}
          confirmButtonTestId="save-details-post-job"
        />
      </div>
      {loading && (
        <>
          <Alert
            severity="info"
            icon={<AutoAwesomeIcon />}
            style={{ marginTop: '20px' }}
          >
            <strong style={{ fontFamily: '"Sofia W05 Medium", sans-serif' }}>
              {t('create_job.step_description.description.waiting_1')}
            </strong>
            <br />
            {t('create_job.step_description.description.waiting_2')}
          </Alert>
        </>
      )}

      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
};
